// src/utils/recaptcha.js

export const executeRecaptcha = async (action) => {
    if (!window.grecaptcha) {
        console.error('reCAPTCHA not loaded');
        throw new Error('reCAPTCHA not loaded');
    }

    try {
        await new Promise(resolve => window.grecaptcha.ready(resolve));
        return await window.grecaptcha.execute('6LegLFcqAAAAAEb7Rh_B3nL_j8avVTLkZhDSkod_', { action });
    } catch (error) {
        console.error('reCAPTCHA execution failed:', error);
        throw error;
    }
}
