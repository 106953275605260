// ~/src/stores/auth.js
import { defineStore } from 'pinia';
import { parseISO, addHours, getTime, addMinutes } from 'date-fns';

import axiosInstance from "@/interceptors/axios.config";
import {ROUTE_NAMES} from "@/router/routes";
import router from "@/router";
import {useMemorialProfileStore} from "@/stores/memorial-profile";

export const useAuthStore = defineStore('authStore', {
    state: () => ({
        isLoggedIn: false,
        currentUserId: null,
        hasAcceptedLatestTerms: false,
        logoutTimer: null,
        dialogTimer: null, // Timer for showing the dialog
        showLogoutDialog: false, // State to control dialog visibility
        showTermsModal: false, // State to control terms dialog visibility
        tokenExpirationTime: null, // Store token expiration time
    }),
    actions: {
        async checkAuth() {
            try {
                const response = await axiosInstance.get('/check-auth/');
                this.isLoggedIn = response.data.is_authenticated;
                this.tokenExpirationTime = getTime(addHours(parseISO(response.data.token_expiration), 2)); // Adjust to CET, token_expiration is UTC
                this.currentUserId = response.data.current_user_id;
            } catch (error) {
                this.isLoggedIn = false;
                this.currentUserId = null;
                this.tokenExpirationTime = null;
            }
        },
        async login(credentials) {
            try {
                const form = new FormData();
                form.append('username', credentials.username);
                form.append('password', credentials.password);

                const response = await axiosInstance.post('/login/', form, {
                    requiresReCaptcha: true,
                    reCaptchaAction: 'login',
                });

                this.isLoggedIn = true;
                this.hasAcceptedLatestTerms = response.data.latest_terms_accepted;
                this.showTermsModal = !this.hasAcceptedLatestTerms;
                this.setAutoLogout(); // Set auto-logout timer

                return response.data;
            } catch (error) {
                this.isLoggedIn = false;
                throw error;
            }
        },
        async refreshAccessToken() {
            try {
                await axiosInstance.post('/refresh');
                this.setAutoLogout(); // Reset auto-logout timer
                this.showLogoutDialog = false; // Hide the dialog
            } catch (error) {
                console.error('Error refreshing access token:', error);
                throw error; // Re-throw the error to be caught in prolongSession
            }
        },
        async logout() {
            if (router.currentRoute._value.path.includes('/edytuj')) {
                const memorialProfileStore = useMemorialProfileStore();
                await memorialProfileStore.updateProfile();
            }

            return new Promise((resolve, reject) => {
                axiosInstance.post('/logout')
                    .then(() => {
                        this.isLoggedIn = false;
                        clearTimeout(this.logoutTimer); // Clear the timer
                        clearTimeout(this.dialogTimer); // Clear the dialog timer
                        this.showLogoutDialog = false; // Hide the dialog

                        const isGoHome = router.currentRoute._value.name === ROUTE_NAMES.DASHBOARD
                            || router.currentRoute._value.name === ROUTE_NAMES.MEMORIAL_PROFILE_EDIT
                            || router.currentRoute._value.name === ROUTE_NAMES.CHANGE_PASSWORD;

                        if (isGoHome) {
                            router.push({ name: ROUTE_NAMES.HOME });
                        }

                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        setAutoLogout() {
            if (this.logoutTimer) {
                clearTimeout(this.logoutTimer);
            }
            if (this.dialogTimer) {
                clearTimeout(this.dialogTimer);
            }

            if (!this.tokenExpirationTime) {
                return;
            }

            const now = getTime(new Date());
            const tokenExpiration = getTime(addHours(this.tokenExpirationTime, 2));
            const fiveMinutesBeforeExpiration = getTime(addMinutes(tokenExpiration, -5));

            // Show dialog 5 minutes before auto-logout
            this.dialogTimer = setTimeout(() => {
                this.showLogoutDialog = true;
            }, fiveMinutesBeforeExpiration - now);

            // Auto-logout at expiration time
            this.logoutTimer = setTimeout(() => {
                this.logout();
            }, tokenExpiration - now);
        },
        prolongSession() {
            this.refreshAccessToken(); // Refresh token to prolong session
        },
    },
});
