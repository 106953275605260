
const passwordPatternRule = () => {
  return v => {
    // The pattern allows for any character (.) in the main part of the pattern,
    // while still requiring at least one lowercase letter, one uppercase letter, and one digit.
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return pattern.test(v) || 'Hasło musi mieć co najmniej 8 znaków i zawierać wielką i małą literę oraz cyfrę.';
  };
}

const passwordsMatchRule = (compareValueOrGetter) => {
  return (value) => {
    // Determine if compareValueOrGetter is a function, if so, call it to get the comparison value
    const comparisonValue = typeof compareValueOrGetter === 'function' ? compareValueOrGetter() : compareValueOrGetter;
    return value === comparisonValue || 'Hasła muszą być takie same';
  };
}

const emailPatternRule = () => {
  return v => /.+@.+\..+/.test(v) || 'Nieprawidłowy adres email'
}

const fieldRequiredRule = () => {
  return v => !!v || 'Pole wymagane';
}

module.exports = { passwordPatternRule, passwordsMatchRule, fieldRequiredRule, emailPatternRule }
