// filename: src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';

import routes from './routes';
import { useAuthStore } from "@/stores/auth";
import axiosInstance from "@/interceptors/axios.config";
import {useMemorialProfileStore} from "@/stores/memorial-profile";
import {toast} from "vue3-toastify";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
});

router.beforeEach(async (to, from, next) => {
  // Set the document title based on the current route
  document.title = to.meta.title || 'Tak Żyli';

  const authStore = useAuthStore();

  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    await authStore.checkAuth();

    if (!authStore.isLoggedIn) {
      next({ name: 'login', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    // Fetch memorial profile if the route is related to memorial profile
    if (to.name === 'memorial-profile-view' || to.name === 'memorial-profile-edit') {
      try {
        const response = await axiosInstance.get(`${process.env.VUE_APP_API_URL}mem-profile/${to.params.slotNumber}`);
        const memorialProfile = response.data;

        const memorialProfileStore = useMemorialProfileStore();
        memorialProfileStore.updateProfileState(memorialProfile);

        if (to.name === 'memorial-profile-edit') {
          if (!authStore.isLoggedIn) {
            return next({ name: 'login' });
          }

          // Check if the logged-in user is the owner of the profile
          if (memorialProfile.owner !== authStore.currentUserId) {
            toast.error('Nie masz uprawnień do edycji tego profilu.');
            return next({ path: '/' }); // Redirect to the home page
          }
        }

        if (to.name === 'memorial-profile-view' && memorialProfile.isPrivate) {
          const isAccessCodeVerified = localStorage.getItem(`access_code_verified_${to.params.slotNumber}`) === 'true';
          if (memorialProfile.owner !== authStore.currentUserId && !isAccessCodeVerified) {
            next({ name: 'access-code-verification', params: { slotNumber: to.params.slotNumber } });
          } else {
            // Clear the access code verification status if verified
            if (isAccessCodeVerified) {
              localStorage.removeItem(`access_code_verified_${to.params.slotNumber}`);
            }
            next();
          }
        } else {
          next();
        }
      } catch (error) {
        console.error('Error fetching memorial profile:', error);
        next({ name: 'home' });
      }
    } else {
      next();
    }
  }
});

export default router;
